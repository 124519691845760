<template>
  <div id="toolbar" class="fixed z-10 flex flex-col bg-blue-500 left-2 right-2 bottom-0 rounded-t-3xl items-center justify-between">
    <!-- Input -->
    <div class="flex flex-row justify-center items-center flex-grow">
      <div v-if="!toolbarInAction" class="flex space-x-4 my-3">
        <CircularButton line1="scansione" line2="targa" :action=1>
          <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 9a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 0110.07 4h3.86a2 2 0 011.664.89l.812 1.22A2 2 0 0018.07 7H19a2 2 0 012 2v9a2 2 0 01-2 2H5a2 2 0 01-2-2V9z" />
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 13a3 3 0 11-6 0 3 3 0 016 0z" />
          </svg>
          <ScanLicensePlate />
        </CircularButton>
        <CircularButton v-if="qrCode" line1="scansione" line2="vetrofania" :action=2>
          <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v1m6 11h2m-6 0h-2v4m0-11v3m0 0h.01M12 12h4.01M16 20h4M4 12h4m12 0h.01M5 8h2a1 1 0 001-1V5a1 1 0 00-1-1H5a1 1 0 00-1 1v2a1 1 0 001 1zm12 0h2a1 1 0 001-1V5a1 1 0 00-1-1h-2a1 1 0 00-1 1v2a1 1 0 001 1zM5 20h2a1 1 0 001-1v-2a1 1 0 00-1-1H5a1 1 0 00-1 1v2a1 1 0 001 1z" />
          </svg>
        </CircularButton>
        <CircularButton line1="inserimento" line2="manuale" :action=3>
          <svg xmlns="http://www.w3.org/2000/svg" class="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
          </svg>
        </CircularButton>
      </div>
      <div v-else class="my-3">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 absolute right-4 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor" @click="UPDATE_TOOLBAR_IN_ACTION(0)">>
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
        </svg>
        <div v-if="toolbarInAction == 2">
          <ScanQr />
        </div>
        <div v-if="toolbarInAction == 3">
          <ManualInsert />
        </div>
      </div>
    </div>
    <!-- Input End -->
    <span class="font-bold text-white">
      SIS Srl
    </span>
  </div>
</template>

<script>
import CircularButton from '@/components/CircularButton.vue'
import ScanQr from '@/components/ScanQr.vue'
import ManualInsert from '@/components/ManualInsert.vue'
import ScanLicensePlate from '@/components/ScanLicensePlate.vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  name: 'Toolbar',
  components: {
    CircularButton,
    ScanQr,
    ManualInsert,
    ScanLicensePlate
  },
  computed: {
    ...mapGetters(['toolbarInAction', 'qrCode'])
  },
  mounted() {
    let toolbarHeight = document.getElementById('toolbar').clientHeight
    this.setSpacing(toolbarHeight)
  },
  methods: {
    ...mapActions(['setSpacing']),
    ...mapMutations(['UPDATE_TOOLBAR_IN_ACTION'])
  }
}
</script>

<style>

</style>
