<template>
  <div>
    <canvas v-show="false" id="canvasocr" />
  </div>
</template>

<script>
import axios from 'axios'
import { mapActions, mapMutations } from 'vuex'

export default {
  name: 'ScanLicensePlate',
  data() {
    return {
      image: {
        width: 1920,
        height: 1080
      }
    }
  },
  mounted() {
    
    let cam = document.getElementById('scanLicensePlate')
    cam.addEventListener('change', (e) => {
      let canvas = document.getElementById('canvasocr')
      let ctx = canvas.getContext('2d')
      var img = new Image();
      img.src = URL.createObjectURL(e.target.files[0])
      
      img.onload = () => {
          this.SWITCH_LOADER()
          canvas.width = this.image.width
          canvas.height = this.image.height
          //ctx.filter = 'grayscale(1) brightness(1.50) contrast(1.45)'
          ctx.filter = 'grayscale(1)'
          ctx.drawImage(img, 0, 0, this.image.width, this.image.height)
          
          canvas.toBlob((blob) => {
            let file = new File([blob], 'snapshot.jpg', { type: 'image/jpeg' })
            
            /* DEBUG DOWNLOAD FOTO FILTER
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(file, 'snapshot.jpg');
            } else{
                var e = document.createEvent('MouseEvents'),
                a = document.createElement('a');
                a.download = 'snapshot.jpg';
                a.href = window.URL.createObjectURL(file);
                a.dataset.downloadurl = ['text/plain', a.download, a.href].join(':');
                e.initEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
                a.dispatchEvent(e);
            }
            */
            
            
            let form = new FormData()
            form.append('image', file)

            axios({
              method: 'post',
              url: 'https://api.openalpr.com/v3/recognize?secret_key=' + process.env.VUE_APP_OPENALPR_KEY + '&recognize_vehicle=0&country=eu&return_image=0&topn=10',
              headers: { 
                'Content-Type': 'application/json',
                'Accept': 'application/json'
              },
              data: form,
              timeout: 30000
            })
              .then((response) => {
                let result = response.data.results
                if (result.length > 0) {
                  // console.log(result[0].plate)
                  this.checkParkings({
                    id: result[0].plate
                  })
                } else {
                  this.SWITCH_NO_PARKINGS_FOUND({
                    status: true,
                    message: 'Targa non identificata, riprovare',
                    licensePlate: null
                  })
                  this.SWITCH_LOADER()
                }
              })
              // eslint-disable-next-line no-unused-vars
              .catch((error) => {
                this.SWITCH_NO_PARKINGS_FOUND({
                  status: true,
                  message: 'Richiesta scaduta, riprovare più tardi',
                  licensePlate: null
                })
                this.SWITCH_LOADER()

              })


          }, 'image/jpeg');

        ctx.clearRect(0, 0, this.image.width, this.image.height)   
      }
    })
  },
  methods: {
    ...mapActions(['checkParkings']),
    ...mapMutations(['SWITCH_LOADER', 'SWITCH_NO_PARKINGS_FOUND'])
  }
}
</script>

<style>

</style>
