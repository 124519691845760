<template>
  <div class="border border-gray-200 rounded-xl flex flex-col p-2 divide-y divide-gray-200 space-y-2">
    <!-- Head -->
    <div class="flex items-center justify-between">
      <span class="text-xl">
        {{ reason }}
      </span>
      <span v-if="onGoing" class="flex h-4 w-4 relative">
        <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-300 opacity-75"></span>
        <span class="relative inline-flex rounded-full h-4 w-4 bg-green-400"></span>
      </span>
      <span v-else class="flex h-4 w-4">
        <span class="relative inline-flex rounded-full h-4 w-4 bg-red-500"></span>
      </span>
    </div>
    <!-- Head End -->
    <!-- Body -->
    <div class="flex flex-col p-1 space-y-3">
      <RowField name="num. abbonamento" :value="subscriptionId" />

      <div class="flex justify-between">
        <RowField name="inizio validità" :value="convertDateTime(startDate)" />
        <RowField name="fine validità" :value="convertDateTime(endDate)" />
      </div>

      <RowField name="codice area" :value="areaCode" />
      <RowField name="prescrizione" :value="prescription" />
    </div>
    <!-- Body End -->
  </div>
</template>

<script>
import RowField from '@/components/RowField.vue'
import moment from 'moment'

export default {
  name: 'SubscriptionRow',
  components: {
    RowField
  },
  props: {
    subscriptionId: String,
    reason: String,
    areaCode: String,
    prescription: String,
    startDate: String,
    endDate: String,
    onGoing: Number
  },
  methods: {
    convertDateTime(date) {
      return moment(date).format('DD/MM/YYYY - HH:mm')
    }
  }
}
</script>

<style>

</style>
