<template>
  <div>
    <input :type="type" @input="$emit('input', $event.target.value)" class="text-xl appearance-none rounded p-0.5 w-full" />
  </div>
</template>

<script>
export default {
  name: 'TextBox',
  props: {
    type: String
  }
}
</script>

<style>

</style>
