<template>
  <div v-if="noParkingsFound.status" class="bg-red-500 rounded-xl py-4 px-2 text-white border-2 border-white shadow mt-12 flex text-lg">
    <span>
      {{ message }} &nbsp;
    </span>
    <span class="font-bold">
      {{ licensePlate }}
    </span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'NoParkingsFound',
  props: {
    message: String,
    licensePlate: String
  },
  computed: {
    ...mapGetters(['noParkingsFound'])
  }
}
</script>

<style>

</style>
