<template>
  <div class="mt-8 flex justify-center">
    <input ref="manualInsert" v-model="licensePlate" type="text" class="text-xl appearance-none rounded-l-lg p-1 w-2/5 border-l border-t border-b border-white uppercase" @keyup.enter="validate()" v-uppercase />
    <div class="bg-blue-700 flex items-center justify-center rounded-r-lg px-1.5 border-r border-t border-b border-white" @click="validate()">
      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
      </svg>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
export default {
  name: 'ManualInsert',
  data() {
    return {
      licensePlate: null
    }
  },
  
  directives: {
    uppercase: {
      update (el) {
        el.value = el.value.replace(/\s/g, '').toUpperCase()
      },
    }
  },
  mounted () {
    this.$refs.manualInsert.focus()
  },
  methods: {
    ...mapActions(['checkParkings']),
    ...mapMutations(['SWITCH_LOADER']),

    validate() {
      if (this.licensePlate.length < 3 ) {
        return
      }
      this.SWITCH_LOADER()
      this.checkParkings({
        id: this.licensePlate.replace(/\s/g, '').toUpperCase()
      })
    }
  }
}
</script>

<style>

</style>
