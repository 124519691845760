<template>
  <div class="border border-gray-200 rounded-xl flex flex-col p-2 divide-y divide-gray-200 space-y-2">
    <!-- Head -->
    <div class="flex items-center justify-between">
      <div class="flex space-x-1.5 justify-center items-center">
        <img :src="'https://his21.net/parkingsAppLogos/' + serviceName + '.png'" class="h-8 w-8 border-2 border-white shadow rounded-full">
        <span class="text-xl">
          {{ serviceName }}
        </span>
      </div>
      <span v-if="onGoing" class="flex h-4 w-4 relative">
        <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-300 opacity-75"></span>
        <span class="relative inline-flex rounded-full h-4 w-4 bg-green-400"></span>
      </span>
      <span v-else class="flex h-4 w-4">
        <span class="relative inline-flex rounded-full h-4 w-4 bg-red-500"></span>
      </span>
    </div>
    <!-- Head End -->
    <!-- Body -->
    <div class="flex flex-col p-1 space-y-3">
      <!-- First Row -->
      <div class="flex justify-between">
        <RowField name="sosta inizio" :value="convertDateTime(startDate)" />
        <RowField name="sosta fine" :value="convertDateTime(endDate)" />
      </div>
      <!-- End First Row -->
      <RowField name="codice area" :value="areaCode" />
    </div>
    <!-- Body End -->
  </div>
</template>

<script>
import RowField from '@/components/RowField.vue'
import moment from 'moment'

export default {
  name: 'ServiceRow',
  components: {
    RowField
  },
  props: {
    serviceName: String,
    startDate: String,
    endDate: String,
    areaCode: String,
    onGoing: Number
  },
  methods: {
    convertDateTime(date) {
      return moment(date).format('DD/MM/YYYY - HH:mm')
    }
  }
}
</script>

<style>

</style>
