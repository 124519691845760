<template>
  <div v-if="licensePlate" class="text-lg flex">
    <span>
      Targa &nbsp;
    </span>
    <span class="font-bold uppercase">
      {{ licensePlate }}
    </span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'LicensePlate',
  computed: {
    ...mapGetters(['licensePlate'])
  },
}
</script>

<style>

</style>
