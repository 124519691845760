<template>
  <div id="app" class="p-4 font-roboto select-none">
    <Home v-if="loginStatus" />
    <Login v-else />
  </div>
</template>

<script>
import Home from '@/components/Home.vue'
import Login from '@/components/Login.vue'
import axios from 'axios'
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'App',
  components: {
    Home,
    Login
  },
  computed: {
    ...mapGetters(['loginStatus'])
  },
  created () {
    let baseUrl = process.env.VUE_APP_API_URL
    let savedToken = localStorage.token

    if (!savedToken) {
      this.setLoginStatus({ 
        'loginStatus': false,
        'municipalityName': null,
        'token': null,
        'qrCode': null
      })
    }

    axios.get(baseUrl + '/check-token', {
      headers: { 
        Authorization: `Bearer ${savedToken}` 
      }
    }).then((response) => {
        this.setLoginStatus({ 
          'loginStatus': true,
          'municipalityName': response.data.municipality_name,
          'token': response.data.token,
          'qrCode': response.data.qr_code
        })
      // eslint-disable-next-line no-unused-vars
      }, (error) => {
        // console.log('token in localstorage non valido')
        this.setLoginStatus({ 
          'loginStatus': false,
          'municipalityName': null,
          'token': null,
          'qrCode': null
        })
      })
  },
  methods: {
    ...mapMutations(['SET_LOGIN_STATUS']),

    // eslint-disable-next-line no-unused-vars
    setLoginStatus (status) {
      this.SET_LOGIN_STATUS({
        'loginStatus': status.loginStatus,
        'municipalityName': status.municipalityName,
        'token': status.token,
        'qrCode': status.qrCode
      })
    }
  }
}
</script>

<style>
body, html {
  touch-action: manipulation;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  padding-top: env(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom);
}
 /* Hide scrollbar for Chrome, Safari and Opera */
body, html:-webkit-scrollbar {
  display: none;
}
</style>
