import axios from 'axios'
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

let baseUrl = process.env.VUE_APP_API_URL

export default new Vuex.Store({
  state: {
    toolbarHeight: null,
    toolbarInAction: 0,
    licensePlate: null,
    loader: false,
    services: null,
    subscriptions: null,
    loginStatus: false,
    municipalityName: null,
    token: null,
    qrCode: 0,
    noParkingsFound: {
      status: false,
      message: null,
      licensePlate: null
    },
    error: {
      message: null
    }
  },
  getters: {
    toolbarHeight: (state) => {
      return state.toolbarHeight + 20
    },
    toolbarInAction: (state) => {
      return state.toolbarInAction
    },
    licensePlate: (state) => {
      return state.licensePlate
    },
    loader: (state) => {
      return state.loader
    },
    services: (state) => {
      return state.services
    },
    subscriptions: (state) => {
      return state.subscriptions
    },
    loginStatus: (state) => {
      return state.loginStatus
    },
    qrCode: (state) => {
      return state.qrCode
    },
    municipalityName: (state) => {
      return state.municipalityName
    },
    noParkingsFound: (state) => {
      return state.noParkingsFound
    },
    error: (state) => {
      return state.error
    }
  },
  mutations: {
    UPDATE_SPACING(state, toolbarHeight) {
      state.toolbarHeight = toolbarHeight
    },
    UPDATE_TOOLBAR_IN_ACTION(state, status) {

      /* Status = 1 corrisponde all'apertura del componente nativo Fotocamera
      * in questo caso non va alterato il toogle della toolbar */

      if (status != 1 ) {
        state.toolbarInAction = status
        
      }
      
    },
    UPDATE_LICENSE_PLATE(state, licensePlate) {
      state.licensePlate = licensePlate
    },
    SWITCH_LOADER(state) {
      state.loader = !state.loader
      state.loader ? document.querySelector('#app').style.overflowY = 'none' : document.querySelector('#app').style.overflowY = 'auto'
    },
    CLEAR_ARRAYS(state) {
      state.services = null
      state.subscriptions = null
    },
    SET_LOGIN_STATUS(state, payload) {
      state.loginStatus = payload.loginStatus
      
      if(payload.loginStatus == true) {

      state.municipalityName = payload.municipalityName
      state.token = payload.token
      state.qrCode = payload.qrCode
      localStorage.setItem('municipalityName', state.municipalityName)
      localStorage.setItem('token', state.token)
      localStorage.setItem('qrCode', state.qrCode)

      }
      // console.log(payload)
    },
    UPDATE_ARRAY_SUBSCRIPTIONS( state, payload ) {
      state.subscriptions = payload.subscriptions
      //commit('UPDATE_LICENSE_PLATE', payload.subscriptions[0].plate)
    },
    UPDATE_ARRAY_SERVICES(state, payload){
      state.services = payload.services
    },
    SWITCH_NO_PARKINGS_FOUND(state, payload){
      state.noParkingsFound = payload
    },
    SET_ERROR_MESSAGE(state, payload) {
      state.error.message = payload.message
    }
  },
  actions: {
    setSpacing({ commit }, toolbarHeight) {
      commit('UPDATE_SPACING', toolbarHeight)
    },

    // eslint-disable-next-line no-unused-vars
    checkParkings({ commit }, payload) {
      commit('UPDATE_TOOLBAR_IN_ACTION', 0)
      commit('UPDATE_LICENSE_PLATE', null)
      commit('CLEAR_ARRAYS')
      commit('SWITCH_NO_PARKINGS_FOUND', {
        status: false,
        message: null,
        licensePlate: null
      })

      // console.log(payload)

      const token = localStorage.getItem('token')

      axios.get(baseUrl + '/check-services/' + payload.id, {
        headers: { 
          Authorization: `Bearer ${token}` 
        },
        timeout: 30000
      })
      .then((response) => {
        let data = response.data

        if (data.parkings.length > 0) {
          commit('UPDATE_ARRAY_SERVICES', {
            services: data.parkings
          })
          commit('UPDATE_LICENSE_PLATE', payload.id)
        }
        
        if (data.subscriptions.length > 0) {
          commit('UPDATE_ARRAY_SUBSCRIPTIONS', {
            subscriptions: data.subscriptions
          })
          // console.log(data.subscriptions)
          commit('UPDATE_LICENSE_PLATE', data.subscriptions[0].plate)
        }

        if (data.parkings.length == 0 && data.subscriptions.length == 0) {
          commit('SWITCH_NO_PARKINGS_FOUND', {
            status: true,
            message: payload.id.length >= 16 ? 'Nessun abbonamento per la vetrofania scansionata' : 'Nessuna sosta per la targa',
            licensePlate: payload.id.length >= 16 ? null : payload.id
          })
        }

        commit('SWITCH_LOADER')
      // eslint-disable-next-line no-unused-vars
      }, (error) => {
        console.log(error.message)
        commit('SWITCH_NO_PARKINGS_FOUND', {
          status: true,
          message: 'Errore: riprovare più tardi',
          licensePlate: null
        })
        commit('SWITCH_LOADER')
      })

    },

    login({ commit }, payload) {
      //pulizia errori precedenti 
      // verifica campi
      commit('SET_ERROR_MESSAGE', { message: null })
      let username = payload.username
      let password = payload.password

      if (username == null || password == null) {
        return commit('SET_ERROR_MESSAGE', { message: 'inserire username e password' })
      }

      axios.post(baseUrl + '/login', {
        username: username,
        password: password
      })
      .then((response) => {
        let data = response.data

        let token = data.token
        let municipalityName = data.municipality_name
        let qrCode = data.qr_code

        commit('SET_LOGIN_STATUS', { 
          'loginStatus': true,
          'token': token,
          'municipalityName': municipalityName,
          'qrCode': qrCode
         })
      }, () => {
        return commit('SET_ERROR_MESSAGE', { message: 'username o password non validi' })
      })
    },
    // eslint-disable-next-line no-unused-vars
    getLicensePlateByQr({ dispatch, commit }, payload) {
      commit('UPDATE_TOOLBAR_IN_ACTION', 0)
      commit('UPDATE_LICENSE_PLATE', null)
      commit('CLEAR_ARRAYS')
      commit('SWITCH_NO_PARKINGS_FOUND', {
        status: false,
        message: null,
        licensePlate: null
      })
      
      const token = localStorage.getItem('token')
      axios.get(baseUrl + '/get-licenseplate-by-qr/' + payload.id, {
        headers: { 
          Authorization: `Bearer ${token}` 
        },
        timeout: 30000
      })
      .then((response) => {
        dispatch('checkParkings', {
          id: response.data
        })
      // eslint-disable-next-line no-unused-vars
      }, (error) => {
        commit('SWITCH_NO_PARKINGS_FOUND', {
          status: true,
          message: payload.id.length >= 16 ? 'Nessun abbonamento per la vetrofania scansionata' : 'Nessuna sosta per la targa',
          licensePlate: payload.id.length >= 16 ? null : payload.id
        })
        commit('SWITCH_LOADER')
      })

    
    },
    logout({ commit }) {

      commit('SET_LOGIN_STATUS', { 
        'loginStatus': false,
        'token': null,
        'municipalityName': null,
        'qrCode': null
      })

      localStorage.removeItem('municipalityName')
      localStorage.removeItem('token')
      localStorage.removeItem('qrCode')

    }
  },
  modules: {
  }
})
