<template>
  <div v-if="value.length > 0" class="flex flex-col -space-y-1.5">
    <span class="text-sm font-light">
      {{ name }}
    </span>
    <span class="text-justify">
      {{ value }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'RowField',
  props: {
    name: String,
    value: String
  }
}
</script>

<style>

</style>
