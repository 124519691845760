<template>
  <div 
    class="flex flex-col justify-center items-center text-white border rounded-full w-24 h-24 space-y-1 hover:bg-blue-400 transition-all relative" 
    @click="UPDATE_TOOLBAR_IN_ACTION(action)">
    <slot />
    <div class="flex flex-col -space-y-2 items-center">
      <span class="text-sm">
        {{ line1 }}
      </span>
      <span class="text-sm">
        {{ line2 }}
      </span>
    </div>
    <input v-show="action == 1" type="file" id="scanLicensePlate" capture="environment" accept="image/*" class="absolute w-full h-full rounded-full opacity-0">
  </div>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
  name: 'CircularButton',
  props: {
    line1: String,
    line2: String,
    action: Number
  },
  mounted() {
    let cam = document.getElementById('scanLicensePlate')
    // eslint-disable-next-line no-unused-vars
    cam.addEventListener('change', (e) => {
      // console.log(e.target.files[0])
    })
  },
  methods: {
    ...mapMutations(['UPDATE_TOOLBAR_IN_ACTION'])
  }
}
</script>

<style>

</style>
