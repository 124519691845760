<template>
  <div class="mt-20 mx-auto w-5/6 p-4 rounded-2xl bg-blue-500 border-2 border-white shadow">
    <div class="flex flex-col space-y-4">
      <div class="flex justify-center">
        <img src="@/assets/logo.png" class="h-16" />
      </div>
      <span class="text-center text-white text-xl">
        Servizio verifica sosta
      </span>
      <TextBox v-model="username" type="text" @keyup.enter="login({'username': username, 'password': password})" />
      <TextBox v-model="password" type="password" @keyup.enter="login({'username': username, 'password': password})" />
      
      <div class="flex justify-center">
        <span class="border border-white rounded-lg text-white text-xl px-2 py-1" @click="login({'username': username, 'password': password})">
          login
        </span>
      </div>
      <div v-if="error.message" class="p-1 border-white border-2 rounded-lg text-white text-center bg-red-500 shadow">
        {{ error.message }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import TextBox from '@/components/TextBox.vue'

export default {
  name: 'Login',
  components: {
    TextBox
  },
  data() {
    return {
      username: null,
      password: null
    }
  },
  computed: {
    ...mapGetters(['error'])
  },
  methods: {
    ...mapActions(['login'])
  }
}
</script>

<style>

</style>
