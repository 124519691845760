<template>
  <div>
    <div id="reader" class="mt-8"></div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { Html5Qrcode } from 'html5-qrcode'

export default {
  name: 'ScanQr',
  computed: {
    ...mapGetters(['loader'])
  },
  mounted() {
    const html5QrCode = new Html5Qrcode('reader')
    const config = { 
      fps: 10,
      qrbox: {
        width: 250,
        height: 250
      } 
    }

    html5QrCode.start({ facingMode: 'environment' }, config, this.qrCodeSuccessCallback);
  },
  methods: {
    ...mapActions(['getLicensePlateByQr']),
    ...mapMutations(['SWITCH_LOADER']),

    qrCodeSuccessCallback(decodedText) {

      if (this.loader) return
      this.SWITCH_LOADER()
      this.getLicensePlateByQr({
        id: decodedText
      })
    }
  }
}
</script>

<style>

</style>
