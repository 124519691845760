<template>
  <div>
    <div class="flex justify-between items-center">
      <span class="text-xl">
        {{ municipalityName }}
      </span>
        <svg xmlns="http://www.w3.org/2000/svg" class="bg-blue-500 text-white rounded-full p-1 h-9 w-9 border-2 border-white shadow" fill="none" viewBox="0 0 24 24" stroke="currentColor" @click="logout()">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
        </svg>
    </div>
    <LicensePlate />
    <!-- Container -->
    <div class="flex flex-col space-y-8 overflow-y-scroll">
      <ServicesList />
      <SubscriptionsList />
    </div>
    <NoParkingsFound :message="noParkingsFound.message" :licensePlate="noParkingsFound.licensePlate" />
    <!-- Necessario per non nascondere il contenuto dietro Toolbar -->
    <div :style="{ height: toolbarHeight + 'px' }" class="w-1" />
    <!-- Container End -->
    <Toolbar />
    <Loader />
  </div>
</template>

<script>
import ServicesList from '@/components/ServicesList.vue'
import SubscriptionsList from '@/components/SubscriptionsList.vue'
import Toolbar from '@/components/Toolbar.vue'
import LicensePlate from '@/components/LicensePlate.vue'
import { mapActions, mapGetters } from 'vuex'
import Loader from '@/components/Loader.vue'
import NoParkingsFound from '@/components/NoParkingsFound.vue'

export default {
name: 'Home',
  components: {
    ServicesList,
    SubscriptionsList,
    Toolbar,
    LicensePlate,
    Loader,
    NoParkingsFound
  },
  computed: {
    ...mapGetters(['toolbarHeight', 'municipalityName', 'noParkingsFound'])
  },
  methods: {
    ...mapActions(['logout'])
  },
}
</script>

<style>

</style>
