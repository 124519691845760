<template>
  <div v-if="services != null">
    <div v-if="services.length > 0" class="flex flex-col space-y-4">
    <span class="ml-0.5 text-3xl font-bold -mb-3">
      Soste con App
    </span>
    <ServiceRow v-for="(ser, jdx) in services" :key="jdx" 
      :serviceName="ser.service_name"
      :startDate="ser.start_date"
      :endDate="ser.end_date"
      :areaCode="ser.area_code"
      :onGoing=ser.on_going
    />
    </div>
    <div v-if="services.length == 0">
      nessuna sosta
    </div> 
  </div>
</template>

<script>
import ServiceRow from '@/components/ServiceRow.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'ServicesList',
  components: {
    ServiceRow
  },
  computed: {
    ...mapGetters(['services'])
  }
}
</script>

<style>

</style>
