<template>
  <div v-if="subscriptions != null">
    <div v-if="subscriptions.length > 0" class="flex flex-col space-y-4">
    <span class="ml-0.5 text-3xl font-bold -mb-3">
      Abbonamenti
    </span>
    <SubscriptionRow v-for="(sub, idx) in subscriptions" :key="idx"
      :subscriptionId="sub.subscription_id"
      :reason="sub.reason"
      :areaCode="sub.area_code"
      :prescription="sub.prescription"
      :startDate="sub.start_date"
      :endDate="sub.end_date"
      :onGoing=sub.on_going
      />
    </div>
    <div v-if="subscriptions.length == 0">
      nesssun abbonamento
    </div>
  </div>
</template>

<script>
import SubscriptionRow from '@/components/SubscriptionRow.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'SubscriptionList',
  components: {
    SubscriptionRow
  },
  computed: {
    ...mapGetters(['subscriptions'])
  }
}
</script>

<style>

</style>
